import { PropertyType } from "./Types"

export const PRICE = [
    {
        label: 'Rp 100jt',
        value: '100.000.000',
    },
    {
        label: 'Rp 200jt',
        value: '200.000.000',
    },
    {
        label: 'Rp 300jt',
        value: '300.000.000',
    },
    {
        label: 'Rp 400jt',
        value: '400.000.000',
    },
    {
        label: 'Rp 500jt',
        value: '500.000.000',
    },
    {
        label: 'Rp 600jt',
        value: '600.000.000',
    },
    {
        label: 'Rp 700jt',
        value: '700.000.000',
    },
    {
        label: 'Rp 800jt',
        value: '800.000.000',
    },
    {
        label: 'Rp 900jt',
        value: '900.000.000',
    },
    {
        label: 'Rp 1M',
        value: '1.000.000.000',
    },
    {
        label: 'Rp 2M',
        value: '2.000.000.000',
    },
    {
        label: 'Rp 3M',
        value: '3.000.000.000',
    },
    {
        label: 'Rp 4M',
        value: '4.000.000.000',
    },
    {
        label: 'Rp 5M',
        value: '5.000.000.000',
    },
    {
        label: 'Rp 6M',
        value: '6.000.000.000',
    },
    {
        label: 'Rp 7M',
        value: '7.000.000.000',
    },
    {
        label: 'Rp 8M',
        value: '8.000.000.000',
    },
    {
        label: 'Rp 9M',
        value: '9.000.000.000',
    },
    {
        label: 'Rp 10M',
        value: '10.000.000.000',
    },
    {
        label: 'Rp 11M',
        value: '11.000.000.000',
    },
    {
        label: 'Rp 12M',
        value: '12.000.000.000',
    },
    {
        label: 'Rp 13M',
        value: '13.000.000.000',
    },
    {
        label: 'Rp 14M',
        value: '14.000.000.000',
    },
    {
        label: 'Rp 15M',
        value: '15.000.000.000',
    },
    {
        label: 'Rp 16M',
        value: '16.000.000.000',
    },
    {
        label: 'Rp 17M',
        value: '17.000.000.000',
    },
    {
        label: 'Rp 18M',
        value: '18.000.000.000',
    },
    {
        label: 'Rp 19M',
        value: '19.000.000.000',
    },
    {
        label: 'Rp 20M',
        value: '20.000.000.000',
    },
]

export const DEFAULT_IMAGE = `${process.env.NEXT_PUBLIC_MAINSITE_URL}/static/images/default-thumbnail-unit.jpg`

export const LOCATION = [
    {
        "value": '0',
        "label": "BSD City"
    },
    {
        "value": '6',
        "label": "Jakarta"
    },
    {
        "value": '92',
        "label": "Cikarang"
    },
    {
        "value": '13',
        "label": "Cibubur"
    },
    {
        "value": '207',
        "label": "Balikpapan"
    },
    {
        "value": '1',
        "label": "Tangerang"
    },
    {
        "value": '18',
        "label": "Batam"
    },
    {
        "value": '9',
        "label": "Surabaya"
    }
]

export const getPriceRangeByType = (propertyType: PropertyType) => {
    switch (propertyType) {
        case 'Apartment':
            return [
                { value: '300000000-1000000000', label: 'Rp 300jt - Rp 1m' },
                { value: '1000000000-2000000000', label: 'Rp 1m - Rp 2m' },
                { value: '2000000000-3000000000', label: 'Rp 2m - Rp 3m' },
                { value: '3000000000-5000000000', label: 'Rp 3m - Rp 5m' },
                { value: '5000000000-', label: '> Rp 5m' },
            ]
        case 'Residential':
        case 'Kavling':
        case 'Ruko':
        case 'Kiosk':
        case 'Warehouse':
        case 'Boutique SOHO':
            return [
                { value: '300000000-1500000000', label: 'Rp 300jt - Rp 1.5m' },
                { value: '1500000000-2000000000', label: 'Rp 1.5m - Rp 2m' },
                { value: '2000000000-3000000000', label: 'Rp 2m - Rp 3m' },
                { value: '3000000000-5000000000', label: 'Rp 3m - Rp 5m' },
                { value: '5000000000-', label: '> Rp 5m' },
            ]
        case 'Business Loft':
            return [
                { value: '1000000000-5000000000', label: 'Rp 1m - Rp 5m' },
                { value: '5000000000-10000000000', label: 'Rp 5m - Rp 10m' },
                { value: '10000000000-20000000000', label: 'Rp 10m - Rp 20m' },
                { value: '20000000000-25000000000', label: 'Rp 20m - Rp 25m' },
                { value: '25000000000-', label: '> Rp 25m' },
            ]
        default:
            return [
                { value: '300000000-1000000000', label: 'Rp 300jt - Rp 1m' },
                { value: '1000000000-2000000000', label: 'Rp 1m - Rp 2m' },
                { value: '2000000000-3000000000', label: 'Rp 2m - Rp 3m' },
                { value: '3000000000-5000000000', label: 'Rp 3m - Rp 5m' },
                { value: '5000000000-', label: '> Rp 5m' },
            ]
    }
}

export const PRICE_RANGE = [
    {
        "value": '300000000-1000000000',
        "label": "Rp 300jt - Rp 1m"
    },
    {
        "value": '1000000000-3000000000',
        "label": "Rp 1m - Rp 3m"
    },
    {
        "value": '3000000000-10000000000',
        "label": "Rp 3m - Rp 10m"
    },
    {
        "value": '10000000000-20000000000',
        "label": "Rp 10m - Rp 20m"
    },
    {
        "value": '20000000000-',
        "label": "> Rp 20m"
    },
]

export const getLandAreaRangeByType = (propertyType: PropertyType) => {
    switch (propertyType) {
        case 'Apartment':
            return [
                { value: '-30', label: '0 m2 - 30 m2' },
                { value: '30-50', label: '30 m2 - 50 m2' },
                { value: '50-70', label: '50 m2 - 70 m2' },
                { value: '70-100', label: '70 m2 - 100 m2' },
                { value: '100-', label: '> 100 m2' },
            ];
        case 'Residential':
            return [
                { value: '-60', label: '0 m2 - 60 m2' },
                { value: '60-80', label: '60 m2 - 80 m2' },
                { value: '80-120', label: '80 m2 - 120 m2' },
                { value: '120-200', label: '120 m2 - 200 m2' },
                { value: '200-', label: '> 200 m2' },
            ];
        case 'Kavling':
            return [
                { value: '-100', label: '0 m2 - 100 m2' },
                { value: '100-150', label: '100 m2 - 150 m2' },
                { value: '150-200', label: '150 m2 - 200 m2' },
                { value: '200-300', label: '200 m2 - 300 m2' },
                { value: '300-', label: '> 300 m2' },
            ];
        case 'Ruko':
        case 'Kiosk':
        case 'Warehouse':
        case 'Boutique SOHO':
            return [
                { value: '-50', label: '0 m2 - 50 m2' },
                { value: '50-70', label: '50 m2 - 70 m2' },
                { value: '70-100', label: '70 m2 - 100 m2' },
                { value: '100-150', label: '100 m2 - 150 m2' },
                { value: '150-', label: '> 150 m2' },
            ];

        case 'Business Loft':
            return [
                { value: '-100', label: '0 m2 - 100 m2' },
                { value: '100-150', label: '100 m2 - 150 m2' },
                { value: '150-200', label: '150 m2 - 200 m2' },
                { value: '200-300', label: '200 m2 - 300 m2' },
                { value: '300-', label: '> 300 m2' },
            ];
        default:
            return [
                { value: '-50', label: '0 m2 - 50 m2' },
                { value: '50-70', label: '50 m2 - 70 m2' },
                { value: '70-100', label: '70 m2 - 100 m2' },
                { value: '100-125', label: '100 m2 - 125 m2' },
                { value: '125-150', label: '125 m2 - 150 m2' },
                { value: '150-200', label: '150 m2 - 200 m2' },
                { value: '200-300', label: '200 m2 - 300 m2' },
                { value: '300-', label: '> 300 m2' },
            ]
    }
};

export const LAND_AREA = [
    {
        "value": '-200',
        "label": "0 m2 - 200 m2"
    },
    {
        "value": '200-400',
        "label": "200 m2 - 400 m2"
    },
    {
        "value": '400-600',
        "label": "400 m2 - 600 m2"
    },
    {
        "value": '600-800',
        "label": "600 m2 - 800 m2"
    },
    {
        "value": '800-',
        "label": "> 800 m2"
    },
]

export const PROPERTY_AREA = [
    {
        "value": '0',
        "label": "0 m2 -150 m2"
    },
    {
        "value": '1',
        "label": "150 m2 - 300 m2"
    },
    {
        "value": '2',
        "label": "300 m2 - 450 m2"
    },
    {
        "value": '3',
        "label": "450 m2 - 600 m2"
    },
    {
        "value": '4',
        "label": "> 600 m2"
    },
]

export const shortCodeRX = /\[carousel_listings\]|\[table_of_contents\]|\[kpr_banner\]/gi