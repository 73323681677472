import { PropertyType } from "./Types"

export const popularApartment = [
    {
        img: "https://jendela360.com/gallery/carousell/5e40f925acf48.jpg",
        title: "Menteng Park",
        price: "5.000.000",
        star: "4.6",
        url: '/sewa-apartemen-menteng-park',
        location: 'Tangerang, Banten'
    },
    {
        img: "https://jendela360.com/gallery/carousell/5e40f982e012c.jpg",
        title: "Taman Anggrek Residence",
        price: "2.420.000",
        star: "4",
        url: '/sewa-apartemen-taman-anggrek-residence',
        location: 'Tangerang, Banten'
    },
    {
        img: "https://jendela360.com/gallery/carousell/5e40f9bdd9bd5.jpg",
        title: "The Mansion Kemayoran Bougenville",
        price: "2.210.000",
        star: "3.8",
        url: '/sewa-apartemen-the-mansion-kemayoran-bougenville',
        location: 'Tangerang, Banten'
    },
    {
        img: "https://jendela360.com/gallery/carousell/5e40f9e00805a.jpg",
        title: "The Mansion Kemayoran Jasmine",
        price: "3.010.000",
        star: "4.3",
        url: '/sewa-apartemen-the-mansion-kemayoran-jasmine',
        location: 'Tangerang, Banten'
    },
    {
        img: "https://jendela360.com/gallery/carousell/5e40fa03deb80.jpg",
        title: "Mediterania Garden Residence 2",
        price: "2.840.000",
        star: "3.6",
        url: '/sewa-apartemen-mediterania-garden-residence-2',
        location: 'Tangerang, Banten'
    },
    {
        img: "https://jendela360.com/gallery/carousell/5e40fa25bec9d.jpg",
        title: "Thamrin Residence Apartment",
        price: "6.250.000",
        star: "4.4",
        url: '/sewa-apartemen-thamrin-residence-apartment',
        location: 'Tangerang, Banten'
    },
    {
        img: "https://jendela360.com/gallery/carousell/5e40faad297f4.jpg",
        title: "Tamansari Semanggi Apartment",
        price: "4.500.000",
        star: "4.4",
        url: '/sewa-apartemen-tamansari-semanggi-apartment',
        location: 'Tangerang, Banten'
    },
    {
        img: "https://jendela360.com/gallery/carousell/5e40fac6474ad.jpg",
        title: "Kemang Village Apartment",
        price: "8.000.000",
        star: "4.3",
        url: '/sewa-apartemen-kemang-village-apartment',
        location: 'Tangerang, Banten'
    },
    {
        img: "https://jendela360.com/gallery/carousell/5e40f925acf48.jpg",
        title: "Menteng Park",
        price: "5.000.000",
        star: "4.6",
        url: '/sewa-apartemen-menteng-park',
        location: 'Tangerang, Banten'
    },
    {
        img: "https://jendela360.com/gallery/carousell/5e40f982e012c.jpg",
        title: "Taman Anggrek Residence",
        price: "2.420.000",
        star: "4",
        url: '/sewa-apartemen-taman-anggrek-residence',
        location: 'Tangerang, Banten'
    },
    {
        img: "https://jendela360.com/gallery/carousell/5e40f9bdd9bd5.jpg",
        title: "The Mansion Kemayoran Bougenville",
        price: "2.210.000",
        star: "3.8",
        url: '/sewa-apartemen-the-mansion-kemayoran-bougenville',
        location: 'Tangerang, Banten'
    },
    {
        img: "https://jendela360.com/gallery/carousell/5e40f9e00805a.jpg",
        title: "The Mansion Kemayoran Jasmine",
        price: "3.010.000",
        star: "4.3",
        url: '/sewa-apartemen-the-mansion-kemayoran-jasmine',
        location: 'Tangerang, Banten'
    },
    {
        img: "https://jendela360.com/gallery/carousell/5e40fa03deb80.jpg",
        title: "Mediterania Garden Residence 2",
        price: "2.840.000",
        star: "3.6",
        url: '/sewa-apartemen-mediterania-garden-residence-2',
        location: 'Tangerang, Banten'
    },
    {
        img: "https://jendela360.com/gallery/carousell/5e40fa25bec9d.jpg",
        title: "Thamrin Residence Apartment",
        price: "6.250.000",
        star: "4.4",
        url: '/sewa-apartemen-thamrin-residence-apartment',
        location: 'Tangerang, Banten'
    },
    {
        img: "https://jendela360.com/gallery/carousell/5e40faad297f4.jpg",
        title: "Tamansari Semanggi Apartment",
        price: "4.500.000",
        star: "4.4",
        url: '/sewa-apartemen-tamansari-semanggi-apartment',
        location: 'Tangerang, Banten'
    },
]

export const apartmentDistrict = [
    {
        url: '/apartemen/jakarta-selatan',
        img: 'https://jendela360.com/gallery/carousell/5b0ff2e8995b5.jpg',
        location: 'jakarta-selatan',
    },
    {
        url: '/apartemen/jakarta-pusat',
        img: 'https://jendela360.com/gallery/carousell/5b0ff21ab305b.jpg',
        location: 'jakarta-pusat',
    },
    {
        url: '/apartemen/jakarta-utara',
        img: 'https://jendela360.com/gallery/carousell/5b0ff3f726cc3.jpg',
        location: 'jakarta-utara',
    },
    {
        url: '/apartemen/jakarta-barat',
        img: 'https://jendela360.com/gallery/carousell/5b0ff3a137592.jpg',
        location: 'jakarta-barat',
    },
    {
        url: '/apartemen/jakarta-timur',
        img: 'https://jendela360.com/gallery/carousell/5b0ff3cddc387.jpg',
        location: 'jakarta-timur',
    },
    {
        url: '/apartemen-ancol',
        img: 'https://jendela360.com/gallery/carousell/5b0ff4b612b5a.jpg',
        location: 'Ancol',
    },
    {
        url: '/apartemen-gandaria',
        img: 'https://jendela360.com/gallery/carousell/5b0ff525ee2b5.jpg',
        location: 'Gandaria',
    },
    {
        url: '/apartemen-kalibata',
        img: 'https://jendela360.com/gallery/carousell/5b0ff560ed0fa.jpg',
        location: 'Kalibata',
    },
    {
        url: '/apartemen-kebon-jeruk',
        img: 'https://jendela360.com/gallery/carousell/5b0ff5a70d007.jpg',
        location: 'Kebon Jeruk',
    },
    {
        url: '/apartemen-kelapa-gading',
        img: 'https://jendela360.com/gallery/carousell/5b0ff5e6b173f.jpg',
        location: 'Kelapa Gading',
    }
]

export const testimonials = [
    {
        img: 'https://jendela360.com/assets/images/media/cnn-c.png',
        img_alt: 'cnn logo',
        content: 'cnn'
    },
    {
        img: 'https://jendela360.com/assets/images/media/techinasia-c.png',
        img_alt: 'techinasia logo',
        content: 'tia'
    },
    {
        img: 'https://jendela360.com/assets/images/media/detik-c.png',
        img_alt: 'detik logo',
        content: 'detik'
    },
    {
        img: 'https://jendela360.com/assets/images/media/metro-c.png',
        img_alt: 'metro logo',
        content: 'metro'
    },
    {
        img: 'https://jendela360.com/assets/images/media/wartaekonomi-c.png',
        img_alt: 'warta logo',
        content: 'warta'
    },
    {
        img: 'https://jendela360.com/assets/images/media/goodnews-c.png',
        img_alt: 'goodnews logo',
        content: 'goodnews'
    },
    {
        img: 'https://jendela360.com/assets/images/media/dailysocial-c.png',
        img_alt: 'dailysocial logo',
        content: 'dailysocial'
    },
]

export const apartments = {
    west: [
        {
            "url": "https://jendela360.com/sewa-apartemen-hayam-wuruk-apartment",
            "title": "Hayam Wuruk Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-taman-anggrek-residence",
            "title": "Taman Anggrek Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-taman-anggrek-condominium-apartment",
            "title": "Taman Anggrek Condominium Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-metro-park-residence",
            "title": "Metro Park Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-madison-park",
            "title": "Madison Park"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-mediterania-gajah-mada-apartment",
            "title": "Mediterania Gajah Mada Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-green-central-city-apartment",
            "title": "Green Central City Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-city-park-apartment",
            "title": "City Park Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-dlofts-kemanggisan",
            "title": "D'lofts Kemanggisan"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-mediterania-garden-residence-1",
            "title": "Mediterania Garden Residence 1"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-puri-park-view-apartment",
            "title": "Puri Park View Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-satu8-residences",
            "title": "Satu8 Residences"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-season-city-apartment",
            "title": "Season City Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-palm-mansion",
            "title": "Palm Mansion"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-veranda-residence",
            "title": "Veranda Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-ciputra-international-puri",
            "title": "Ciputra International Puri"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-centro-city-apartment",
            "title": "Centro City Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-grand-tropic-suites-apartment",
            "title": "Grand Tropic Suites Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-puri-mansion",
            "title": "Puri Mansion "
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-bandara-city-apartment",
            "title": "Bandara City Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-paradise-mansion-apartment",
            "title": "Paradise Mansion Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-akr-gallery-west-residence",
            "title": "AKR Gallery West Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-green-palm-residence",
            "title": "Green Palm Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-puri-park-residence",
            "title": "Puri Park Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-central-park-apartment",
            "title": "Central Park Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-st-moritz-apartment",
            "title": "ST Moritz Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-the-crest-west-vista-puri",
            "title": "The Crest West Vista Puri"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-slipi-apartment",
            "title": "Slipi Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-wang-residence",
            "title": "Wang Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-kedoya-elok-apartment",
            "title": "Kedoya Elok Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-westmark-apartment",
            "title": "Westmark Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-puri-orchard-apartment",
            "title": "Puri Orchard Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-neo-soho-residence",
            "title": "Neo Soho Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-sky-terrace-apartment",
            "title": "Sky Terrace Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-royal-mediterania-garden-residence",
            "title": "Royal Mediterania Garden Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-green-park-view-apartment",
            "title": "Green Park View Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-maqna-residence",
            "title": "Maqna Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-city-resort-apartment",
            "title": "City Resort Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-city-garden-apartment",
            "title": "City Garden Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-permata-eksekutif-apartment",
            "title": "Permata Eksekutif Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-vittoria-residence",
            "title": "Vittoria Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-b-residence-grogol",
            "title": "B-Residence Grogol"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-sentraland-cengkareng-apartment",
            "title": "Sentraland Cengkareng Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-mediterania-garden-residence-2",
            "title": "Mediterania Garden Residence 2"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-semanan-indah-apartment",
            "title": "Semanan Indah Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-green-sedayu-apartment",
            "title": "Green Sedayu Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-menara-kebon-jeruk-apartment",
            "title": "Menara Kebon Jeruk Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-puri-garden-apartment",
            "title": "Puri Garden Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-belmont-residence",
            "title": "Belmont Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-the-windsor",
            "title": "The Windsor"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-menara-latumenten",
            "title": "Menara Latumenten"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-lucky-tower-residence",
            "title": "Lucky Tower Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-wesling-kedoya-apartment",
            "title": "Wesling Kedoya Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-citra-living-apartment",
            "title": "Citra Living Apartment"
        }
    ],
    north: [
        {
            "url": "https://jendela360.com/sewa-apartemen-aston-pluit-de-paradiso-apartment",
            "title": "Aston Pluit De Paradiso Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-condominium-green-bay-pluit-seaview",
            "title": "Condominium Green Bay Pluit SeaView"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-metro-sunter-apartment",
            "title": "Metro Sunter Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-laguna-pluit-apartment",
            "title": "Laguna Pluit Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-gading-resort-residence",
            "title": "Gading Resort Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-mitra-bahari-apartment",
            "title": "Mitra Bahari Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-aston-marina-ancol",
            "title": "Aston Marina Ancol"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-paladian-park",
            "title": "Paladian Park"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-de-ploeit-centrale-apartment",
            "title": "De Ploeit Centrale Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-pluit-sea-view",
            "title": "Pluit Sea View"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-green-lake-sunter-apartment",
            "title": "Green Lake Sunter Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-teluk-intan-apartment",
            "title": "Teluk Intan Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-northland-ancol-residence",
            "title": "Northland Ancol Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-gading-nias-apartment",
            "title": "Gading Nias Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-gold-coast-apartment",
            "title": "Gold Coast Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-the-kensington-royal-suites",
            "title": "The Kensington Royal Suites"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-maple-park-apartment",
            "title": "Maple Park Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-wisma-gading-permai",
            "title": "Wisma Gading Permai"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-kondominium-menara-kelapa-gading",
            "title": "Kondominium Menara Kelapa Gading"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-gading-green-hill-apartment",
            "title": "Gading Green Hill Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-regatta-apartment",
            "title": "Regatta Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-sherwood-residence",
            "title": "Sherwood Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-ancol-mansion-apartment",
            "title": "Ancol Mansion Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-the-villas-moi",
            "title": "The Villas MOI"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-moi-frenchwalk",
            "title": "MOI Frenchwalk"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-the-park-residence",
            "title": "The Park Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-summerville-apartment",
            "title": "Summerville Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-cbd-pluit-apartment",
            "title": "CBD Pluit Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-sedayu-city-apartment",
            "title": "Sedayu City Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-pantai-mutiara-apartment",
            "title": "Pantai Mutiara Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-gading-mediterania-residence",
            "title": "Gading Mediterania Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-sunter-icon",
            "title": "Sunter Icon"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-mediterania-marina-ancol-apartment",
            "title": "Mediterania Marina Ancol Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-green-bay-pluit-apartment",
            "title": "Green Bay Pluit Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-tokyo-riverside-apartment",
            "title": "Tokyo Riverside Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-moi-city-home",
            "title": "MOI City Home"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-the-summit-apartment",
            "title": "The Summit Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-pluit-residenseas",
            "title": "Pluit Residenseas"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-sunter-park-view-apartment",
            "title": "Sunter Park View Apartment"
        }
    ],
    center: [
        {
            "url": "https://jendela360.com/sewa-apartemen-best-western-mangga-dua",
            "title": "Best Western Mangga Dua"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-mitra-oasis-residence",
            "title": "Mitra Oasis Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-the-mansion-kemayoran-jasmine",
            "title": "The Mansion Kemayoran Jasmine"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-green-pramuka-city-apartment",
            "title": "Green Pramuka City Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-elpis-residence-apartment",
            "title": "Elpis Residence Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-gp-plaza-apartment",
            "title": "GP Plaza Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-mediterania-palace-kemayoran",
            "title": "Mediterania Palace Kemayoran"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-sudirman-hill-residence",
            "title": "Sudirman Hill Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-red-top-apartment",
            "title": "Red Top Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-menteng-park",
            "title": "Menteng Park"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-capitol-suites-apartment",
            "title": "Capitol Suites Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-sudirman-park-apartment",
            "title": "Sudirman Park Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-menara-jakarta-apartment",
            "title": "Menara Jakarta Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-permata-senayan-apartment",
            "title": "Permata Senayan Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-the-mansion-kemayoran-bougenville",
            "title": "The Mansion Kemayoran Bougenville"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-anandamaya-residence",
            "title": "Anandamaya Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-capitol-park",
            "title": "Capitol Park"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-cosmo-terrace-thamrin-city",
            "title": "Cosmo Terrace - Thamrin City"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-graha-cempaka-apartment",
            "title": "Graha Cempaka Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-thamrin-residence-apartment",
            "title": "Thamrin Residence Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-arandra-residence",
            "title": "Arandra Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-springhill-terrace-residence",
            "title": "Springhill Terrace Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-casa-domaine-apartment",
            "title": "Casa Domaine Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-sahid-sudirman-residence",
            "title": "Sahid Sudirman Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-park-royal-apartment",
            "title": "Park Royal Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-amethyst-kemayoran-apartment",
            "title": "Amethyst Kemayoran Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-istana-sahid-apartment",
            "title": "Istana Sahid Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-kempinski-grand-indonesia-apartment",
            "title": "Kempinski Grand Indonesia Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-menteng-regency",
            "title": "Menteng Regency"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-ascott-apartment",
            "title": "Ascott Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-pasar-baru-mansion-apartment",
            "title": "Pasar Baru Mansion Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-the-boulevard-apartment",
            "title": "The Boulevard Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-semanggi-apartment",
            "title": "Semanggi Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-puri-kemayoran-apartment",
            "title": "Puri Kemayoran Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-salemba-residence",
            "title": "Salemba Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-menteng-executive-apartment",
            "title": "Menteng Executive Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-batavia-apartment",
            "title": "Batavia Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-mediterania-lagoon-residence",
            "title": "Mediterania Lagoon Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-mediterania-boulevard-kemayoran",
            "title": "Mediterania Boulevard Kemayoran"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-pavilion-apartment",
            "title": "Pavilion Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-the-boutique-at-kemayoran",
            "title": "The Boutique At Kemayoran"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-city-lofts-apartment",
            "title": "City Lofts Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-the-stature-jakarta-apartment",
            "title": "The Stature Jakarta Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-1-cik-ditiro-apartment",
            "title": "1 Cik Ditiro Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-royale-springhill-residence",
            "title": "Royale SpringHill Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-sudirman-suites-jakarta",
            "title": "Sudirman Suites Jakarta"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-condominium-rajawali-apartment",
            "title": "Condominium Rajawali Apartment "
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-taman-kemayoran-kondominium",
            "title": "Taman Kemayoran Kondominium"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-cosmo-mansion-thamrin-city",
            "title": "Cosmo Mansion - Thamrin City"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-menteng-square-apartment",
            "title": "Menteng Square Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-grand-kartini-apartment",
            "title": "Grand Kartini Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-cik-ditiro-residence",
            "title": "Cik Ditiro Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-mangga-dua-court-apartment",
            "title": "Mangga Dua Court Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-grand-palace-kemayoran",
            "title": "Grand Palace Kemayoran"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-t-plaza-residence",
            "title": "T Plaza Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-thamrin-executive-residence",
            "title": "Thamrin Executive Residence"
        }
    ],
    east: [
        {
            "url": "https://jendela360.com/sewa-apartemen-oak-tower-apartment",
            "title": "Oak Tower Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-citra-landmark-ciracas",
            "title": "Citra Landmark Ciracas"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-sentra-timur-residence",
            "title": "Sentra Timur Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-cibubur-village-apartment",
            "title": "Cibubur Village Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-east-park-apartment",
            "title": "East Park Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-casablanca-east-residence",
            "title": "Casablanca East Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-mth-square",
            "title": "MTH Square"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-bassura-city-apartment",
            "title": "Bassura City Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-tamansari-the-hive",
            "title": "Tamansari The Hive"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-callia-apartment",
            "title": "Callia Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-tifolia-apartment",
            "title": "Tifolia Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-signature-park-grande",
            "title": "Signature Park Grande"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-cleon-park",
            "title": "Cleon Park"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-gading-icon-apartment",
            "title": "Gading Icon Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-delta-cakung-apartment",
            "title": "Delta Cakung Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-the-h-residence",
            "title": "The H Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-menara-cawang-apartment",
            "title": "Menara Cawang Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-titanium-square-apartment",
            "title": "Titanium Square Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-patria-park-apartment",
            "title": "Patria Park Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-mt-haryono-residence",
            "title": "MT Haryono Residence"
        }
    ],
    south: [
        {
            "url": "https://jendela360.com/sewa-apartemen-nifarro-park-apartment",
            "title": "Nifarro Park Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-district-8",
            "title": "District 8"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-the-18th-residence-taman-rasuna",
            "title": "The 18Th Residence Taman Rasuna"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-belleza-apartment",
            "title": "Belleza Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-pakubuwono-spring-apartment",
            "title": "Pakubuwono Spring Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-ciputra-world-2-apartment",
            "title": "Ciputra World 2 Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-permata-hijau-residence",
            "title": "Permata Hijau Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-grand-itc-permata-hijau",
            "title": "Grand ITC Permata Hijau"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-gardenia-boulevard-apartment",
            "title": "Gardenia Boulevard Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-senayan-apartment",
            "title": "Senayan Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-the-peak-apartment",
            "title": "The Peak Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-senayan-residence",
            "title": "Senayan Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-the-mansion-kemang",
            "title": "The Mansion Kemang"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-pakubuwono-view",
            "title": "Pakubuwono View"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-utopia-extraordinary-living-residence",
            "title": "Utopia Extraordinary Living Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-ambassade-residence",
            "title": "Ambassade Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-daksa-residence",
            "title": "Daksa Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-kemang-village-apartment",
            "title": "Kemang Village Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-ra-residence",
            "title": "Ra Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-parama-apartment",
            "title": "Parama Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-senayan-city-residence",
            "title": "Senayan City Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-pejaten-park-residence",
            "title": "Pejaten Park Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-kasamara-residence",
            "title": "Kasamara Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-the-elements-kuningan-apartment",
            "title": "The Elements Kuningan Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-nirvana-residence",
            "title": "Nirvana Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-midtown-residence-jakarta",
            "title": "Midtown Residence Jakarta"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-verde-residence",
            "title": "Verde Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-pacific-place-residence",
            "title": "Pacific Place Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-brawijaya-apartment",
            "title": "Brawijaya Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-pondok-indah-golf-apartment",
            "title": "Pondok Indah Golf Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-soho-pancoran-apartment",
            "title": "Soho Pancoran Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-bonavista-apartment",
            "title": "BonaVista Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-fraser-residence",
            "title": "Fraser Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-synthesis-residence-kemang",
            "title": "Synthesis Residence Kemang"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-synthesis-tower",
            "title": "Synthesis Tower"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-verde-two-apartment",
            "title": "Verde Two Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-setiabudi-sky-garden",
            "title": "Setiabudi Sky Garden"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-izzara-apartment",
            "title": "Izzara Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-the-grove-apartment",
            "title": "The Grove Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-1-park-residence",
            "title": "1 Park Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-bellagio-mansion",
            "title": "Bellagio Mansion"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-permata-hijau-suites-apartment",
            "title": "Permata Hijau Suites Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-pondok-club-villa",
            "title": "Pondok Club Villa"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-oakwood-suites-la-maison",
            "title": "Oakwood Suites La Maison"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-the-wave-apartment",
            "title": "The Wave Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-taman-puri-permata-hijau",
            "title": "Taman Puri Permata Hijau"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-purnawarman-executive-mansion",
            "title": "Purnawarman Executive Mansion"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-mayflower-apartment-indofood-tower",
            "title": "Mayflower Apartment (Indofood Tower)"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-the-loggia-apartment",
            "title": "The Loggia Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-arkadia-residence-at-kemang",
            "title": "Arkadia Residence at Kemang"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-fountain-park-apartment",
            "title": "Fountain Park Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-tamansari-semanggi-apartment",
            "title": "Tamansari Semanggi Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-golfhill-terrace-apartment",
            "title": "Golfhill Terrace Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-pakubuwono-house",
            "title": "Pakubuwono House"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-permata-hijau-apartment",
            "title": "Permata Hijau Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-pakubuwono-residence",
            "title": "Pakubuwono Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-puri-imperium-apartment",
            "title": "Puri Imperium Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-the-padmayana-apartment",
            "title": "The Padmayana Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-lexington-residence",
            "title": "Lexington Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-branz-simatupang-apartment",
            "title": "Branz Simatupang Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-the-breeze-bintaro-apartment",
            "title": "The Breeze Bintaro Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-sahid-metropolitan-residence",
            "title": "Sahid Metropolitan Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-pollux-sky-suites",
            "title": "Pollux Sky Suites"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-1-park-avenue",
            "title": "1 Park Avenue"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-solterra-place-apartment",
            "title": "Solterra Place Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-kusuma-chandra-apartment",
            "title": "Kusuma Chandra Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-south-quarter-tb-simatupang",
            "title": "South Quarter TB Simatupang"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-kuningan-place-apartment",
            "title": "Kuningan Place Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-arumaya-residence",
            "title": "Arumaya Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-taman-rasuna-apartment",
            "title": "Taman Rasuna Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-taman-sari-sudirman",
            "title": "Taman Sari Sudirman"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-wijaya-executive-mansion",
            "title": "Wijaya Executive Mansion"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-fx-residence",
            "title": "FX Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-botanica-apartment",
            "title": "Botanica Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-senopati-apartment",
            "title": "Senopati Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-pancoran-riverside-apartment",
            "title": "Pancoran Riverside Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-residence-8-senopati",
            "title": "Residence 8 Senopati"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-bellagio-residence",
            "title": "Bellagio Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-simprug-indah-apartment",
            "title": "Simprug Indah Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-south-hills-apartment",
            "title": "South Hills Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-sinabung-mansion",
            "title": "Sinabung Mansion"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-altuera-southgate",
            "title": "Altuera Southgate"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-pakubuwono-signature",
            "title": "Pakubuwono Signature"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-setiabudi-residence",
            "title": "Setiabudi Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-somerset-permata-berlian-residence",
            "title": "Somerset Permata Berlian Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-lavenue-apartment",
            "title": "Lavenue Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-the-masterpiece-condominium-epicentrum",
            "title": "The Masterpiece Condominium Epicentrum"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-kemang-jaya-apartment",
            "title": "Kemang Jaya Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-pakubuwono-terrace",
            "title": "Pakubuwono Terrace"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-la-maison-barito-apartment",
            "title": "La Maison Barito Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-beverly-tower-apartment",
            "title": "Beverly Tower Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-cervino-village",
            "title": "Cervino Village"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-marbella-kemang-residence-apartment",
            "title": "Marbella Kemang Residence Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-puri-casablanca-apartment",
            "title": "Puri Casablanca Apartment "
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-setiabudi-apartment",
            "title": "Setiabudi Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-bumi-mas-apartment",
            "title": "Bumi Mas Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-essence-darmawangsa-apartment",
            "title": "Essence Darmawangsa Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-permata-gandaria-apartment",
            "title": "Permata Gandaria Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-royal-olive-residence",
            "title": "Royal Olive Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-ambassador-1-apartment",
            "title": "Ambassador 1 Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-kebagusan-city-apartment",
            "title": "Kebagusan City Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-the-capital-residences",
            "title": "The Capital Residences"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-kalibata-city-apartment",
            "title": "Kalibata City Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-grand-pakubuwono-terrace",
            "title": "Grand Pakubuwono Terrace"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-aspen-residence-apartment",
            "title": "Aspen Residence Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-bintaro-plaza-residence",
            "title": "Bintaro Plaza Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-nine-residence",
            "title": "Nine Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-pondok-indah-residence",
            "title": "Pondok Indah Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-kebayoran-icon-apartment",
            "title": "Kebayoran Icon Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-casablanca-mansion",
            "title": "Casablanca Mansion"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-gandaria-heights-apartment",
            "title": "Gandaria Heights Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-woodland-park-residence-kalibata",
            "title": "Woodland Park Residence Kalibata"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-aston-rasuna-apartment",
            "title": "Aston Rasuna Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-fatmawati-city-center-apartment",
            "title": "Fatmawati City Center Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-casa-grande-apartment",
            "title": "Casa Grande Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-gateway-apartment",
            "title": "Gateway Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-ocea-condotel",
            "title": "OCEA Condotel"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-martimbang-villa",
            "title": "Martimbang Villa"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-the-bellevue-suites-apartment",
            "title": "The Bellevue Suites Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-kuningan-city-denpasar-residence",
            "title": "Kuningan City (Denpasar Residence)"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-somerset-grand-citra-kuningan",
            "title": "Somerset Grand Citra Kuningan "
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-pearl-garden-apartment",
            "title": "Pearl Garden Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-casablanca-apartment",
            "title": "Casablanca Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-kalibata-city-green-palace",
            "title": "Kalibata City Green Palace"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-nuansa-hijau-green-view-apartment",
            "title": "Nuansa Hijau (Green View) Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-lavande-residence",
            "title": "Lavande Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-executive-paradise-complex",
            "title": "Executive Paradise Complex"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-sudirman-tower-condominium-aryaduta-suites-semanggi",
            "title": "Sudirman Tower Condominium (Aryaduta Suites Semanggi)"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-ambassador-2-apartment",
            "title": "Ambassador 2 Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-ascott-kuningan-jakarta",
            "title": "Ascott Kuningan Jakarta"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-hamptons-park-apartment",
            "title": "Hamptons Park Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-poins-square-apartment",
            "title": "Poins Square Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-mutiara-executive-residence",
            "title": "Mutiara Executive Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-bintaro-park-view",
            "title": "Bintaro Park View"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-signature-park-apartment",
            "title": "Signature Park Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-sudirman-residence",
            "title": "Sudirman Residence"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-sudirman-mansion-apartment",
            "title": "Sudirman Mansion Apartment"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-the-accent-bintaro",
            "title": "The Accent Bintaro"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-four-winds",
            "title": "Four Winds"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-la-vie-all-suites",
            "title": "La Vie All Suites"
        },
        {
            "url": "https://jendela360.com/sewa-apartemen-wijaya-executive-suites",
            "title": "Wijaya Executive Suites"
        }
    ]
}

export const media = [
    {
        img: 'https://jendela-space.sgp1.cdn.digitaloceanspaces.com/assets/rent-apartment/media-tempo.png',
        img_alt: 'tempo.co logo',
        content: 'media-tempo'
    },
    {
        img: 'https://jendela-space.sgp1.cdn.digitaloceanspaces.com/assets/rent-apartment/media-tribunnews.png',
        img_alt: 'tribunnews logo',
        content: 'media-tribunnews'
    },
    {
        img: 'https://jendela-space.sgp1.cdn.digitaloceanspaces.com/assets/rent-apartment/media-bisnis.png',
        img_alt: 'bisnis.com logo',
        content: 'media-bisnis'
    },
]

export const categoriesLabelOption = [
    { label: 'All Categories', value: '', },
    // { label: 'What’s on Sinarmas Land', value: 'whatsnew', },
    { label: 'Promotion', value: 'promotion', },
    { label: 'Residential', value: 'residential', },
    { label: 'Kavling Lot', value: 'kavling', },
    { label: 'Apartment', value: 'apartment', },
    { label: 'Shophouse', value: 'shophouse', },
    { label: 'Business Loft', value: 'businessloft', },
    { label: 'Kiosk', value: 'kiosk', },
    { label: 'Berita Terkini', value: 'berita-terkini', },
    { label: 'Teknologi', value: 'teknologi', },
    { label: 'Gaya Hidup & Hobi', value: 'gaya-hidup-hobi', },
    { label: 'Keuangan & Investasi', value: 'keuangan-investasi', },
    { label: 'Info KPR', value: 'info-kpr', },
    { label: 'Tips Properti', value: 'tips-properti', },
    { label: 'Desain', value: 'desain', },
    { label: 'Inspirasi Hunian', value: 'inspirasi-hunian', },
]

interface IPriceOptions {
    value: { min: string, max: string }
    label: string
}
type IPriceHelperOptionsByProperty = Record<PropertyType, IPriceOptions[]>

export const priceHelperOptionByProperty: IPriceHelperOptionsByProperty = {
    Apartment: [
        { value: { min: '300.000.000', max: '1.000.000.000' }, label: 'Rp300jt-1M' },
        { value: { min: '1.000.000.000', max: '2.000.000.000' }, label: 'Rp1-2M' },
        { value: { min: '2.000.000.000', max: '3.000.000.000' }, label: 'Rp2-3M' },
        { value: { min: '3.000.000.000', max: '5.000.000.000' }, label: 'Rp3-5M' },
        { value: { min: '5.000.000.000', max: '' }, label: '>Rp5M' }
    ],
    Residential: [
        { value: { min: '300.000.000', max: '1.500.000.000' }, label: 'Rp300jt-1.5M' },
        { value: { min: '1.500.000.000', max: '2.000.000.000' }, label: 'Rp1.5-2M' },
        { value: { min: '2.000.000.000', max: '3.000.000.000' }, label: 'Rp2-3M' },
        { value: { min: '3.000.000.000', max: '5.000.000.000' }, label: 'Rp3-5M' },
        { value: { min: '5.000.000.000', max: '' }, label: '>Rp5M' }
    ],
    Kavling: [
        { value: { min: '300.000.000', max: '1.500.000.000' }, label: 'Rp300jt-1.5M' },
        { value: { min: '1.500.000.000', max: '2.000.000.000' }, label: 'Rp1.5-2M' },
        { value: { min: '2.000.000.000', max: '3.000.000.000' }, label: 'Rp2-3M' },
        { value: { min: '3.000.000.000', max: '5.000.000.000' }, label: 'Rp3-5M' },
        { value: { min: '5.000.000.000', max: '' }, label: '>Rp5M' }
    ],
    Ruko: [
        { value: { min: '300.000.000', max: '1.500.000.000' }, label: 'Rp300jt-1.5M' },
        { value: { min: '1.500.000.000', max: '2.000.000.000' }, label: 'Rp1.5-2M' },
        { value: { min: '2.000.000.000', max: '3.000.000.000' }, label: 'Rp2-3M' },
        { value: { min: '3.000.000.000', max: '5.000.000.000' }, label: 'Rp3-5M' },
        { value: { min: '5.000.000.000', max: '' }, label: '>Rp5M' }
    ],
    Kiosk: [
        { value: { min: '300.000.000', max: '1.500.000.000' }, label: 'Rp300jt-1.5M' },
        { value: { min: '1.500.000.000', max: '2.000.000.000' }, label: 'Rp1.5-2M' },
        { value: { min: '2.000.000.000', max: '3.000.000.000' }, label: 'Rp2-3M' },
        { value: { min: '3.000.000.000', max: '5.000.000.000' }, label: 'Rp3-5M' },
        { value: { min: '5.000.000.000', max: '' }, label: '>Rp5M' }
    ],
    "Business Loft": [
        { value: { min: '1.000.000.000', max: '5.000.000.000' }, label: 'Rp1-5M' },
        { value: { min: '5.000.000.000', max: '10.000.000.000' }, label: 'Rp5-10M' },
        { value: { min: '10.000.000.000', max: '20.000.000.000' }, label: 'Rp10-20M' },
        { value: { min: '20.000.000.000', max: '25.000.000.000' }, label: 'Rp20-25M' },
        { value: { min: '25.000.000.000', max: '' }, label: '>Rp25M' }
    ],
    Warehouse: [
        { value: { min: '300.000.000', max: '1.500.000.000' }, label: 'Rp300jt-1.5M' },
        { value: { min: '1.500.000.000', max: '2.000.000.000' }, label: 'Rp1.5-2M' },
        { value: { min: '2.000.000.000', max: '3.000.000.000' }, label: 'Rp2-3M' },
        { value: { min: '3.000.000.000', max: '5.000.000.000' }, label: 'Rp3-5M' },
        { value: { min: '5.000.000.000', max: '' }, label: '>Rp5M' }
    ],
    "Boutique SOHO": [
        { value: { min: '300.000.000', max: '1.500.000.000' }, label: 'Rp300jt-1.5M' },
        { value: { min: '1.500.000.000', max: '2.000.000.000' }, label: 'Rp1.5-2M' },
        { value: { min: '2.000.000.000', max: '3.000.000.000' }, label: 'Rp2-3M' },
        { value: { min: '3.000.000.000', max: '5.000.000.000' }, label: 'Rp3-5M' },
        { value: { min: '5.000.000.000', max: '' }, label: '>Rp5M' }
    ],
    All: [
        { value: { min: '300.000.000', max: '1.000.000.000' }, label: 'Rp300jt-1M' },
        { value: { min: '1.000.000.000', max: '2.000.000.000' }, label: 'Rp1-2M' },
        { value: { min: '2.000.000.000', max: '3.000.000.000' }, label: 'Rp2-3M' },
        { value: { min: '3.000.000.000', max: '5.000.000.000' }, label: 'Rp3-5M' },
        { value: { min: '5.000.000.000', max: '' }, label: '>Rp5M' }
    ]
};
